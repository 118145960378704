.devops-header-main{
    display:block;
}
.devops-header-mobile{
    display:none !important;
}
.devops-header-mobile-menu{
    position: absolute;
    top: 0px;
    left: 0px;
    height: 400vh;
    width: 340px;
    background-color: white;
    z-index: 999;
    background-color: navy;
    transition: all 0.3s ease-in-out;
}
.devops-header-mobile-menu-off{
    position: absolute;
    top: 0px;
    left: -340px;
    height: 400vh;
    width: 340px;
    background-color: white;
    z-index: 999;
    background-color: navy;
    transition: all 0.3s ease-in-out;
}

.course-header{
    font-size: 52px;
    font-weight: bold;
    color: black;
}
.course-header-btn{
    margin-top: 20px !important;
}
@media (max-width: 600px) {
    .devops-header-main{
        display:none !important;
    }
    .devops-header-mobile{
        display:block !important;
    }
    .course-header{
        font-size: 22px;
        font-weight: bold;
        color: black;
        margin: auto;
    }
    .course-header-btn{
        margin-top: 0px !important;
    }
  }

.card-main-container:hover{
border: 3px solid gray;
cursor: pointer;
box-shadow: 0px 0px 16px rgb(54, 53, 53);
}