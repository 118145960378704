.accordion-li{
    font-size: 24px;
    padding: 10px;
    cursor: pointer;
    }
    
    .accordion-li:hover{
        background-color: rgb(211, 233, 250);
    }
    .accordian-container::-webkit-scrollbar {
        width: 8px; /* width of the entire scrollbar */
        background-color: #ffffff;
    }
    .accordian-container::-webkit-scrollbar-thumb {
        background: rgb(2, 2, 174); /* Scrollbar thumb color */
        border-radius: 10px;
        height: 30px;
    }
    .accordian-container{
        margin: 5px;
        overflow-x: hidden;
    }
 
    .course-description::-webkit-scrollbar {
        width: 8px; /* width of the entire scrollbar */
        background-color: #ffffff;
    }
    .course-description::-webkit-scrollbar-thumb {
        background: rgb(2, 7, 155); /* Scrollbar thumb color */
        border-radius: 10px;
        height: 30px;
    }
    .course-description{
        margin: 5px;
        overflow-x: hidden;
        height: 35vh;
    }
    
    
    /* For Firefox */
    