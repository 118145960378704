.play-thumb {
    position: absolute;
    right: 50%;
    top: 74%;
    transform: translate(-50%, -50%);
  }
  .play-thumb-home {
    z-index: 2;
    right: 289px;
    top: 64%;
  }
  
  .play-thumb a {
    position: absolute;
    right: 50%;
    bottom: 0;
    height: 90px;
    width: 90px;
    line-height: 90px;
    text-align: center;
    background: #fff;
    border-radius: 50%;
    padding-left: 5px;
    font-size: 25px;
    color: #3763eb;
    margin-left: -55px;
  }
  
  .play-thumb a:hover {
    background-color: #3763eb;
    color: #fff;
  }
  
  .play-thumb a::before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border: 1px solid #fff;
    border-radius: 50%;
    -webkit-animation: pulse-border-2 1.5s linear infinite;
    animation: pulse-border-2 1.5s linear infinite;
  }
  
  .play-thumb a::after {
    position: absolute;
    content: "";
    left: 44px;
    top: 44px;
    height: 100%;
    width: 100%;
    border: 1px solid #fff;
    border-radius: 50%;
    -webkit-animation: pulse-border 1s linear infinite;
    animation: pulse-border 1s linear infinite;
  }