.comments{
    height: 500px;
    overflow-x: hidden !important;
    overflow-y: scroll;
}
.comments::-webkit-scrollbar {
    margin-top: 20px;
    width: 8px; /* width of the entire scrollbar */
    background-color: #ffffff;
}
.comments::-webkit-scrollbar-thumb {
    margin-top: 20px;
    background: rgb(0, 0, 34); /* Scrollbar thumb color */
    border-radius: 10px;
    height: 30px;
}