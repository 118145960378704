.questionbar-main{
    background-color: #002147;
 
    position:absolute;
    top: 50px;
    left: -19px;
    width: 400px;
    height: 100vh;
    z-index: 999;
    overflow-x: hidden !important;
    overflow-y: scroll;
    transition: left 0.5s ease !important;
}
.questionbar-toggle{
    background-color: #002147;
    position:absolute;
    top: 50px;
    left: -415px !important;
    width: 400px;
    height: 100vh;
    z-index: 999;
    overflow-x: hidden !important;
    overflow-y: hidden;
    transition: left 0.5s ease !important;
}
.question-item:hover{
background-color: rgba(143, 195, 255, 0.2);
cursor: pointer;
border-radius: 5px;
}
.questionbar-main::-webkit-scrollbar {
    margin-top: 20px;
    width: 10px; /* width of the entire scrollbar */
    background-color: #014390;
    padding: 1px;
}
.questionbar-main::-webkit-scrollbar-thumb {
    margin: 2px !important;
    background: rgb(248, 251, 93); /* Scrollbar thumb color */
    border-radius: 10px;
    height: 30px;
}