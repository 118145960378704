@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300..700&family=Poppins:wght@500&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
 
body{
  font-family:'Poppins', sans-serif;
}

.subscription-tag{
  font-size: 22px;
  /* font-weight: bold; */
  padding: 10px 0px;
}

@media (max-width: 600px) {
  .subscription-tag{
    font-size: 8px;
  /* font-weight: bold; */
  padding: 5px 2px;
 
  }
}